import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Site } from '@apps/www/src/__generated__/graphql';
import SVSiteTemplateThumbnail from '@apps/www/src/www/components/SVSiteTemplateThumbnail';
import SVGlobalLoadingWrapper from '@apps/www/src/www/containers/SVGlobalLoadingWrapper';
import SVPageMeta from '@apps/www/src/www/containers/SVPageMeta';
import SVRedirect from '@apps/www/src/www/containers/SVRedirect';
import useAllowedFeatureState, {
	ALLOWED_STATES,
} from '@apps/www/src/www/hooks/useAllowedFeatureState';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import SVCheckoutSubscriptionModal from '@apps/www/src/www/modals/SVCheckoutSubscriptionModal';
import SelectSiteTemplateMutation from '@apps/www/src/www/queries/SelectSiteTemplateMutation';
import SiteQuery from '@apps/www/src/www/queries/SiteQuery';
import SVButton, { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVCenter from '@pkgs/shared-client/components/SVCenter';
import SVLandingPageFooter from '@pkgs/shared-client/components/SVLandingPageFooter';
import SVLink from '@pkgs/shared-client/components/SVLink';
import SVMessage from '@pkgs/shared-client/components/SVMessage';
import SVModal from '@pkgs/shared-client/components/SVModal';
import SVPageHeader from '@pkgs/shared-client/components/SVPageHeader';
import SVPageMargins from '@pkgs/shared-client/components/SVPageMargins';
import SVPageTitle from '@pkgs/shared-client/components/SVPageTitle';
import SVVideo from '@pkgs/shared-client/components/SVVideo';
import config from '@pkgs/shared-client/config';
import fieldNameFromQuery from '@pkgs/shared-client/helpers/fieldNameFromQuery';
import { formatURL } from '@pkgs/shared-client/helpers/format';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import avatarCloud from '@pkgs/shared-client/img/site-maker/avatar-cloud.png';
import exampleOne from '@pkgs/shared-client/img/site-maker/example-one.png';
import exampleTwo from '@pkgs/shared-client/img/site-maker/example-two.png';
import stepOne from '@pkgs/shared-client/img/site-maker/step-one.png';
import stepThree from '@pkgs/shared-client/img/site-maker/step-three.png';
import stepTwo from '@pkgs/shared-client/img/site-maker/step-two.png';
import templates from '@pkgs/shared-client/img/site-maker/templates.png';
import customizeFont from '@pkgs/shared-client/videos/customize-font.mp4';
import customizeFontWebm from '@pkgs/shared-client/videos/customize-font.webm';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import SiteTemplate from '@pkgs/shared/enums/SiteTemplate';
import SubscriptionTier from '@pkgs/shared/enums/SubscriptionTier';
import objectKeys from '@pkgs/shared/helpers/objectKeys';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const STEP_ITEMS: {
	title: string;
	description: string;
	img: string;
}[] = [
	{
		title: 'Select your template',
		description: 'Start with one of our beautiful\n templates designed for you.',
		img: stepOne,
	},
	{
		title: 'Choose a board',
		description: 'Create a board and add your\n work directly to it.',
		img: stepTwo,
	},
	{
		title: 'Publish',
		description: 'All done! Publish your site and\n start sharing it with your clients.',
		img: stepThree,
	},
];

const _ImagesMoveOnScroll = () => {
	const [scrollPosition, setScrollPosition] = useState<number>(0);

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className="mt-12 h-[50vw] w-full overflow-hidden">
			<div className="relative w-full">
				<div className="absolute" style={{ left: -scrollPosition * 0.5 }}>
					<picture>
						<source srcSet={exampleOne} media="(min-width: 1024px)" />
						<img alt="Example sites" src={exampleOne} className="mt-10 w-full" />
					</picture>
				</div>
				<div className="absolute" style={{ right: -scrollPosition * 0.5 }}>
					<picture>
						<source srcSet={exampleTwo} media="(min-width: 1024px)" />
						<img
							alt="Example sites"
							src={exampleTwo}
							className="-md:mt-[23vw] -sm:mt-[28vw] -md:mt-30 mt-[21vw] w-full"
						/>
					</picture>
				</div>
			</div>
		</div>
	);
};

const _Content = ({ site }: { site: Site | null }) => {
	const client = useApolloClient();
	const [selectSiteTemplate, { loading: _loading, error }] = useMutation(
		SelectSiteTemplateMutation,
	);
	const isLoggedIn = useIsLoggedIn();
	const router = useRouter();

	const handleSubmit = useEventCallback(
		async (templateKey: (typeof SiteTemplate)[keyof typeof SiteTemplate]) => {
			await selectSiteTemplate({
				variables: {
					input: {
						template: templateKey,
					},
				},
			});

			const fieldName = fieldNameFromQuery(SiteQuery);
			if (fieldName) {
				client.cache.evict({
					id: 'ROOT_QUERY',
					fieldName,
				});
			}

			client.cache.gc();

			router.push(`/site-maker/choose-board/`);
		},
	);

	const handleSelectTemplate = useEventCallback(
		(templateKey: (typeof SiteTemplate)[keyof typeof SiteTemplate]) => {
			handleSubmit(templateKey);
		},
	);

	const handleSiteMakerClick = useEventCallback(() => {
		if (!isLoggedIn) {
			router.push(`/login/?next=${encodeURIComponent('/upgrade/')}`);
			return;
		}

		SVModal.open(SVCheckoutSubscriptionModal, {
			tier: SubscriptionTier.SITE_MAKER,
		});
	});

	const hasSiteMaker =
		useAllowedFeatureState(AllowedFeature.CREATE_SITE) === ALLOWED_STATES.ALLOWED;

	if (site) {
		return <SVRedirect to="/site-maker/edit/" />;
	}

	return (
		<>
			{hasSiteMaker ? (
				<>
					<SVPageHeader>
						<div className="type-subtitle text-primary font-semibold">
							Choose your template
						</div>
					</SVPageHeader>
					<div className="-sm:px-4 -md:grid-cols-2 -sm:grid-cols-1 m-auto grid w-full max-w-[1500px] grid-cols-3 gap-9 px-24">
						{objectKeys(config.sites.templates).map((templateKey) => {
							return (
								<div
									key={templateKey}
									className="cursor-pointer"
									onClick={() => handleSelectTemplate(templateKey)}
								>
									<SVSiteTemplateThumbnail templateKey={templateKey} />
								</div>
							);
						})}
					</div>
					<SVCenter className="mt-16">
						{error && <SVMessage use={SVMessage.USES.ERROR}>{error.message}</SVMessage>}
					</SVCenter>
				</>
			) : (
				<div className="flex w-full max-w-full flex-col overflow-hidden">
					<SVPageHeader>
						<div className="mb-5 font-semibold text-gray-600">BETA</div>
						<SVPageTitle>Simple and Beautiful&nbsp;Sites</SVPageTitle>
						<div className="type-subnav text-center">
							Build your site with a few clicks.
						</div>
						<SVButton
							Component={SVLink}
							to={'/site-maker/create/'}
							use={SVButtonUSES.PRIMARY}
							onClick={handleSiteMakerClick}
							className="mt-6 w-fit"
						>
							Create your site
						</SVButton>
					</SVPageHeader>
					<_ImagesMoveOnScroll />
					<div>
						<SVPageMargins className="flex flex-col items-center">
							<SVPageTitle className="pt-32">3 simple steps</SVPageTitle>
							<div className="-md:space-x-0 -md:flex-col relative mt-20 flex w-auto items-center justify-between space-x-20">
								{STEP_ITEMS.map((item, index) => (
									<div
										key={index}
										className="-md:mb-12 flex flex-col items-center text-center"
									>
										<div className="p-5">
											<img
												src={item.img}
												alt="steps frame"
												className="-md:h-60 -sm:h-40 -md:w-60 -sm:w-52 h-40  w-80  object-contain"
											/>
										</div>
										<div>
											<div className="-sm:text-3xl mt-2 text-5xl font-semibold text-gray-300">
												{item.title}
											</div>
											<div
												className="text-muted mt-2"
												style={{ whiteSpace: 'pre-line' }}
											>
												<p>{item.description}</p>
											</div>
										</div>
									</div>
								))}
							</div>
						</SVPageMargins>
						<SVPageMargins className="-md:pt-24 flex flex-col items-center pt-52">
							<SVPageTitle>Your own domain</SVPageTitle>
							<div className="text-muted mt-6 text-center">
								Use your username.savee.site domain or connect your own.
							</div>
							<div className="relative mt-12">
								<img
									src={avatarCloud}
									className="relative z-20 w-[55vw]"
									alt="avatar cloud"
								/>
								<div
									className={`absolute top-[calc(50%-16.9vw)] z-10 w-full text-center text-[22.9vw] font-semibold `}
									style={{
										WebkitTextStrokeWidth: '0.007em',
										WebkitTextStrokeColor: '#2C2C2C',
										color: 'transparent',
										WebkitBackgroundClip: 'text',
									}}
								>
									.com
								</div>
							</div>
						</SVPageMargins>
					</div>
					<SVPageMargins>
						<div className={`-sm:flex-col -sm:pt-24 flex justify-between pt-40`}>
							<div className="flex flex-1 flex-col items-center justify-center">
								<div
									className={`-sm:mb-14 -sm:mr-0 mr-10 flex min-w-[200px] max-w-[270px] flex-col`}
								>
									<SVPageTitle>
										Customize
										<br />
										font&nbsp;styles
									</SVPageTitle>
									<div
										className={`-sm:text-center type-pagenav mt-5 text-gray-400 text-opacity-60`}
									>
										Change background
										<br />
										and text color.
									</div>
								</div>
							</div>
							<div>
								<SVVideo
									sources={[
										formatURL(config.staticURL, customizeFont),
										formatURL(config.staticURL, customizeFontWebm),
									]}
									muted
									autoPlay
									playsInline
									loop
									className="-lg:mr-10 -md:mr-0 mr-56 h-full w-full"
								/>
							</div>
						</div>
						<div className="mt-48 flex justify-between">
							<div className={'-sm:flex-1'}>
								<img
									src={templates}
									className={`-sm:w-full -sm:object-right-bottom -sm:h-full -sm:object-cover -md:ml-32 -sm:ml-0 ml-56 w-[25.6vw]`}
									alt="inspiration"
								/>
							</div>
							<div
								className={`-sm:flex-[2] -sm:px-3 flex flex-1 flex-col items-center justify-center`}
							>
								<div className="ml-10 flex flex-col">
									<SVPageTitle className="text-left">
										Stand out
										<br />
										with a Beautiful
										<br />
										template
									</SVPageTitle>
									<div className="type-pagenav text-gray-400 text-opacity-60">
										Select from a range of options we
										<br />
										have curated for you.
									</div>
								</div>
							</div>
						</div>
					</SVPageMargins>
					<SVPageMargins>
						<div className={'flex items-center pt-48 pb-4'}>
							<SVButton
								className="m-auto w-full max-w-[320px]"
								use={SVButtonUSES.PRIMARY}
								Component={SVLink}
								to="/site-maker/create/"
								onClick={handleSiteMakerClick}
							>
								Start Creating
							</SVButton>
						</div>
						<SVLandingPageFooter className={'mt-40'} />
					</SVPageMargins>
				</div>
			)}
		</>
	);
};

const _SVCreateSiteMakerPage = () => {
	const isLoggedIn = useIsLoggedIn();
	const router = useRouter();
	const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
	const { data, loading } = useQuery(SiteQuery, {
		// Skip if not logged in, we can still render the page for non-logged in users.
		skip: !isLoggedIn,
	});

	const meta = {
		title: 'Create your site',
	};

	// Prevents the page from being instantly redirected to `site-maker/edit` where to clear the client cache
	useEffect(() => {
		const handleRouteChangeStart = (_url: string) => {
			setIsRedirecting(true);
		};

		router.events.on('routeChangeStart', handleRouteChangeStart);

		return () => {
			router.events.off('routeChangeStart', handleRouteChangeStart);
		};
	}, [router]);

	return (
		<>
			<SVPageMeta {...meta} />
			<SVGlobalLoadingWrapper isLoading={loading || isRedirecting}>
				{() => <_Content site={data?.site ?? null} />}
			</SVGlobalLoadingWrapper>
		</>
	);
};

const SVCreateSiteMakerPage = _SVCreateSiteMakerPage;

export default SVCreateSiteMakerPage;
